import React from "react";

const REGISTER = 'register'
const LOGIN  = 'login'
const QUEUED_PARTNERS = 'get-queue-partners'
const LOCOMOTION_TYPES = 'get-locomotion-types'
const LOCOMOTION_GENERIC_FEATURES = 'get-locomotion-generic-features'
const LOCOMOTION_CATEGORIES = 'get-locomotion-categories'
const ACTIVATE_PARTNER_ACCOUNT = 'activate-partner-account'
const GET_PUBS = 'get-pubs'
const CREATE_PUB = 'create-pubs'
const GET_USERS = 'get-users'
const DELETE_PUB = 'delete-pub'
const GET_USER_COUNT = 'get-user-count'
const GET_PUBS_COUNT = 'get-pubs-count'
const GET_CARS_COUNT = 'get-cars-count'
const GET_SECTORS = 'get-sectors'
const GET_VENTURES = 'get-ventures'
const GET_ADS = 'get-ads'
const CREATE_SECTOR = 'create-sector'
const DELETE_SECTOR = 'delete-sector'
const CREATE_VENTURE = 'create-venture'
const DELETE_VENTURE = 'delete-venture'
const GET_RESERVATIONS_COUNT = 'get-reservations-count'
const UPDATE_VENTURE = 'update-venture'
const CREATE_ADS = 'create-ads'
const CREATE_CAR = 'add-a-locomotion'
const GET_LOCOMOTION_CATEGORIES = 'get-locomotion-categories'

const GET_DEPOSITS = 'get-transactions'
const HANDLE_DEPOSITE = 'handle-transaction'

const ADD_PRODUCT = 'create-product'
const GET_PRODUCTS = 'get-products'
const DELETE_PRODUCT = 'delete-product'
const GET_WITHDRAWALS = 'get-withdrawal-requests'
const GET_SUBSCRIPTIONS = 'get-product-subscriptions'
const GET_CONFIGS = 'get-configs'
const UPDATE_CONFIGS = 'update-configs'

const GET_DISCOUNTS = 'get-discounts'
const CREATE_DISCOUNT = 'create-discount'
const DELETE_DISCOUNT = 'delete-discount'

const UPDATE_LOCOMOTION_CATEGORIES_PRICE = 'update-locomotion-categories-price'

// Errands manipulation

const GET_ERRANDS = 'get-all-errands'


// Majoration manipulation

const GET_MAJORATIONS = 'get-majorations'

const CREATE_MAJORATION = "create-majoration"

const UPDATE_MAJORATION = "update-majoration"

const DELETE_MAJORATION = "delete-majoration"

const GET_ERRAND = 'get-errand'

const GET_USER = 'get-user'
const GET_ROLES = 'get-roles/658752'

const FILTER_USER_BY_ROLE = 'filter-by-role'

const ACTIVATE_PERSONALIZED_COMMISSION = 'activate-ps-commission'

const LOCK_USER_ACCOUNT = 'lock-account'

// Categories

const GET_CATEGORIES = 'get-categories'
const CREATE_CATEGORY = 'create-category'
const UPDATE_CATEGORY = 'update-category'
const DELETE_CATEGORY = 'delete-category'

// Houses/Taxes

const GET_TAXABLE_HOUSES = 'get-taxable-houses'

const PAY_TAXE = 'pay-taxes'

// Money withdrawal

const MONEY_WITHDRAWAL = 'withdraw-money'

// Booking

const GET_BOOKINGS = 'get-flats-booking'

const VALIDATE_BOOKING = 'validate-booking'

// Stats

const GET_ERRAND_BALANCE = 'get-errand-balance'
const GET_PRODUCT_BALANCE = 'get-product-balance'
const GET_RENTAL_REAL_ESTATE_BALANCE = 'get-rental-real-estate-balance'
const GET_SAVING_BALANCE = 'get-saving-product-balance'
const GET_INSURANCE_BALANCE = 'get-insurance-product-balance'

const GET_PENDING_CARS = 'get-pending-cars'
const SET_PENDING_CARS = 'set-pending-cars'

const BROADCAST_MESSAGES = 'broadcast-messages'

const GET_ONLINE_USERS = 'get-connected-users'

// Dealing with unactivated furnished flats

const GET_UNACTIVATED_FLATS = 'get-unactivated_furnished_flats'
const ACTIVATE_FURNISHED_FLAT = 'update-furnished-flat-status'

const GET_UNACTIVATED_RENTAL_PROPERTIES = 'get-unactivated-rental-propeties'
const ACTIVATE_RENTAL_PROPERTY = 'update-rental-property-status'

const ACTIVATE_GUESTHOUSE = 'activate-guest-houses'
const GET_UNACTIVATED_GUESTHOUSE = 'get-unactivated-guesthouses'

const SET_DRIVER_COMMISSION = 'set-driver-commission'

const TRX_PASSWORD_CHECK = 'trx-password-check'

const GET_USER_METRICS = 'get-user-metrics'

// 

const DELETE_USER_ACCOUNT = 'delete-user-account'

const GET_TRANSACTIONS_HISTORY = 'get-transactions-history'

const WALLET_OP = 'wallet-op'

// Leasable cars

const DELETE_LEASABLE_CAR = 'delete-leasable-car'

const BK_GET_LEASABLE_CARS = 'bk-get-leasable-cars'

const CLOSE_SUBSCRIPTION = 'handle-subscription'

// Sellables

const GET_UNACTIVATED_SELLABLES = 'bk-get-unactivated-sellables'
const ACTIVATE_SELLABLE = 'activate-sellable'

const GET_BUYER_INTERESTED = 'get-buyer-interested'
const DELETE_BUYER_INTERESTED = 'delete-buyer-interested'

// Car bookings

const GET_CAR_BOOKINGS = 'bk-get-car-bookings'

const DELETE_CAR_BOOKING = 'bk-delete-car-bookings'

// Transaction

const DELETE_TRANSACTION = 'delete-transaction'

export {LOGIN,QUEUED_PARTNERS,LOCOMOTION_TYPES,
    LOCOMOTION_GENERIC_FEATURES,LOCOMOTION_CATEGORIES,
    ACTIVATE_PARTNER_ACCOUNT,GET_PUBS,CREATE_PUB,GET_USERS,DELETE_PUB,GET_USER_COUNT,
    GET_PUBS_COUNT,GET_CARS_COUNT,GET_SECTORS,GET_ADS,GET_VENTURES,CREATE_SECTOR,DELETE_SECTOR,CREATE_VENTURE,DELETE_VENTURE,
    GET_RESERVATIONS_COUNT,UPDATE_VENTURE,CREATE_ADS,CREATE_CAR,GET_LOCOMOTION_CATEGORIES,
    GET_DEPOSITS,HANDLE_DEPOSITE,ADD_PRODUCT,GET_PRODUCTS,DELETE_PRODUCT,GET_WITHDRAWALS,
    GET_SUBSCRIPTIONS,UPDATE_LOCOMOTION_CATEGORIES_PRICE,GET_CONFIGS,UPDATE_CONFIGS,GET_DISCOUNTS,CREATE_DISCOUNT,DELETE_DISCOUNT,
    GET_ERRANDS,GET_MAJORATIONS,CREATE_MAJORATION,DELETE_MAJORATION,UPDATE_MAJORATION,GET_ERRAND,GET_USER,FILTER_USER_BY_ROLE,
    GET_ROLES,ACTIVATE_PERSONALIZED_COMMISSION,LOCK_USER_ACCOUNT,CREATE_CATEGORY,GET_CATEGORIES,UPDATE_CATEGORY,DELETE_CATEGORY,
    GET_TAXABLE_HOUSES,PAY_TAXE,MONEY_WITHDRAWAL,GET_BOOKINGS,VALIDATE_BOOKING,GET_ERRAND_BALANCE,GET_PRODUCT_BALANCE,
    GET_RENTAL_REAL_ESTATE_BALANCE,GET_PENDING_CARS,SET_PENDING_CARS,BROADCAST_MESSAGES,GET_ONLINE_USERS,GET_UNACTIVATED_FLATS,
    ACTIVATE_FURNISHED_FLAT,GET_UNACTIVATED_RENTAL_PROPERTIES,ACTIVATE_RENTAL_PROPERTY,SET_DRIVER_COMMISSION,TRX_PASSWORD_CHECK,
    GET_USER_METRICS,DELETE_USER_ACCOUNT,GET_TRANSACTIONS_HISTORY,WALLET_OP,GET_SAVING_BALANCE,GET_INSURANCE_BALANCE,
    GET_UNACTIVATED_GUESTHOUSE,ACTIVATE_GUESTHOUSE,DELETE_LEASABLE_CAR,BK_GET_LEASABLE_CARS,CLOSE_SUBSCRIPTION,
    GET_UNACTIVATED_SELLABLES,ACTIVATE_SELLABLE,GET_BUYER_INTERESTED,DELETE_BUYER_INTERESTED,GET_CAR_BOOKINGS,
    DELETE_CAR_BOOKING,DELETE_TRANSACTION
}

export default REGISTER