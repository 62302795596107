import { useContext, useEffect, useState } from "react"
import { AppStore } from "../../App"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CheckBadgeIcon, ExclamationTriangleIcon, EyeIcon, EyeSlashIcon, ShoppingBagIcon, TruckIcon } from "@heroicons/react/24/outline";
import CarService from "./car.service";
import GlobalLoader from "../../components/GlobalLoader";
import Spinner from "../../components/Spinner";
import ImageZoom from "../../components/ImageZoom";
import { TrashIcon } from "@heroicons/react/20/solid";

export default function LeasableCars()
{

    const {store,dispatch} = useContext(AppStore)

    const [zoomActive,setZoomActive] = useState(false)

    const [cars,setCars] = useState([])

    const [link,setLink] = useState(null)

    const fetchLeasableCars = async () => {

        dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

        await CarService.getLeasableCars()
        .then(res => {

            if(res)
            {

                dispatch({type : 'TOGGLE_APP_LOADING', payload : false})
            
                setCars(res.data.cars)

            }

        })

    }

    const setPendingCar = async (e,id,status) => {
        
        e.preventDefault()

        let text = status == 0 ? 'activer' : 'desactiver'

        let alt = status == 0 ? 'active' : 'desactive'

        if(window.confirm(`Voulez vous vraiment ${text} ce vehicule ?`))
        {

            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

            let data = {
                id : id
            }
    
            let response = await CarService.setPendingCar(data)
    
            if(response.status == 200)
            {
                toast.success(`Le véhicule est maintenant ${alt}`)
                dispatch({type : 'TOGGLE_APP_LOADING', payload : false})
                await fetchLeasableCars()
            }

        }

    }

    const setZoom = (e,link) => {

        e.preventDefault()

        dispatch({type : 'SET_ZOOM',payload : true})

        setLink(link)

    }

    const deleteLeasableCar = async (e,id) => {
        
        e.preventDefault()

        if(window.confirm('Voulez vous vraiment supprimer ce vehicule ?'))
        {

            dispatch({type : 'TOGGLE_APP_LOADING', payload : true})

            let data = {
                id : id
            }
    
            let response = await CarService.deleteLeasableCar(data)
    
            if(response.status == 200)
            {
                toast.success('Le véhicule est maintenant active')
                dispatch({type : 'TOGGLE_APP_LOADING', payload : false})
                fetchLeasableCars()
            }

        }

    }

    useEffect(() => {

        fetchLeasableCars()  
        localStorage.setItem('page','other')

    },[])

    return (

        <section className="w-full">

                <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg mb-14 flex items-center justify-between">
                    <div className="">
                        <TruckIcon className="w-6 inline-flex text-black"/>
                        <span className="font-bold pl-2 text-black">Véhicules en location</span>
                    </div>
                </header>

                <ToastContainer/>

                {store.loading ? <Spinner/> : 

                    <article className="w-full">

                        <div className="bg-orange-600 text-black font-bold my-10 p-5">
                            <ExclamationTriangleIcon className="w-6 inline-flex text-black"/>
                            <span className="pl-2">Veuillez contacter le proprietaire avant de supprimer un vehicule. Ledit vehicule pourrait etre celui 
                            qu'il utilise pour faire des courses si il est un conducteur actif sur la plate-forme.</span>
                        </div>

                        {
                            cars.length == 0 ?

                            <div>Aucun véhicules en attente</div> : 

                            <table className="w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    #
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Utilisateur
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Role
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Marque
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Image avant
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Image arriere
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Statut
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Action
                                    </th>
                                </tr>
                                </thead> 
                                <tbody className="divide-y divide-gray-200 bg-white">
                                {cars.map(car => (
                                    <tr key={car?.id}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {car?.id}
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                            {car?.owner?.c_name}
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                            {car?.owner?.role}
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                            {car?.brand}
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                            <a onClick={e => setZoom(e,`${car?.front_pic}`)}  className="text-blue-600 hover:text-blue-900 cursor-pointer">
                                                Voir l'image
                                            </a>
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                            <a onClick={e => setZoom(e,`${car?.back_pic}`)} className="text-blue-600 hover:text-blue-900 cursor-pointer">
                                                Voir l'image
                                            </a>
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                                            
                                            {car?.validated == 0 ? 'En attente' : 'Validé'}

                                        </td>
                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex gap-3">
                                            <a title="Desactiver ce vehicule" onClick={e => setPendingCar(e,car?.id,car.validated)}  className="text-gray-600 hover:text-gray-900 cursor-pointer">
                                                {
                                                    car.validated ? <EyeSlashIcon className="w-6"/> : ''
                                                }
                                                {
                                                    !car.validated ? <EyeIcon className="w-6"/> : ''
                                                }
                                                {
                                                    car.validated ? 'Desactiver' : 'Activer'
                                                }
                                            </a>
                                            <a onClick={e => deleteLeasableCar(e,car?.id)}  className="text-red-600 hover:text-red-900 cursor-pointer">
                                                <TrashIcon className="w-6"/>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>

                        }

                        {store.userDetails.zoomActive && <ImageZoom link={link}/>}

                    </article>                

                }
            
        </section>

    )

}