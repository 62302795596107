import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "../../services/AuthProvider.service";
import useRequest from "../../hooks/useRequest";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { GET_PUBS } from "../../resources/routes";

import AddPub from "../../components/AddPub";
import PubMember from "../../components/PubMember";
import Spinner from "../../components/Spinner";
import { AppStore } from "../../App";

import {
    NewspaperIcon,
    ArrowPathIcon,
    MapIcon,
    LinkIcon
  } from '@heroicons/react/24/outline'

export default function Pubs({ctx_memo})
{

    const {store,dispatch} = useContext(AppStore)

    const {postData,getData} = useRequest()

    const [addv,setAddv] = useState(false)

    const [pubs,setPubs] = useState([])

    const [loading, setLoading] = useState(true)

    const fetch_pubs = async () => {

        let response = await getData(GET_PUBS).then(res => {

            if(res.status == 401)
            {
                // logout()
            }
    
            if(res.status == 200)
            {
                    // alert(JSON.stringify(res.data))
                dispatch({type : 'SET_PUBS', payload : res.data.pubs})
            }
            else{
                    // alert
                toast.warning('Something went wrong')
            }           

            setLoading(false)

        })
        .catch(err => toast.error(err.message))

    }

    const fetchMemo = useMemo(() => ({fetch_pubs:fetch_pubs}))

    useEffect(() => {

        fetch_pubs()
        localStorage.setItem('page','other')

    },[])

    return (
    
        <section className="w-full">

            <header className="shadow-current text-gray-600 shadow p-4 rounded text-lg mb-14 flex justify-between">
                <div className="">
                    <LinkIcon className="w-6 inline-flex text-black"/>
                    <span className="font-bold pl-2 text-black">Vos publicités</span>
                </div>
                <div className="">
                    <button onClick={() => dispatch({type : 'TOGGLE_PUB_PAGE_ADD_VIEW',payload : true})} className="bg-blue-800 px-4 py-2 text-sm rounded cursor-pointer text-white ml-5 inline-flex items-center justify-center">Ajouter +</button>
                </div>
            </header>

                {
                    store.pubsPage.indicators.addViewUp && <AddPub ctx_memo={AuthContext}/>
                }

                {
                    loading ? 

                    <Spinner/> : 

                    <article className="">

                        {
                            store.pubs.length > 0 ? 

                            <div className="grid grid-cols-3 gap-4">
                                {store.pubs.map(pub => <PubMember link={pub.link} image={pub.pic} id={pub.id}/>)}
                            </div> : 

                            <div>
                                <span>Aucune publicité disponible</span>
                            </div>
                        }

                    </article>

                }
            
        </section>

    )

}